button {    
    font-weight: bold;
    font-size: 1.5vh;
}

.disabledButton {
    opacity: 0.5;
}

/* Post-entrance main container */

.gameContainer {
    width: 100%;
    height: 100%;
    display: grid;    
    row-gap: 2%;
    grid-template-columns: 100%;
    grid-template-rows: 10% 90%;
}

/* Game Entry -------------------------------------------------------------- */

.winScoreSelector {
    height: max-content;
    padding: 3% 0%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2vh;
}

.scoreSelectionForm {
    padding-top: 1%;
}

.enteringSceneContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.gameLogo {
    height: 30vh;
}

.demoCheckboxContainer {
    font-size: 2vh;
}

.demoCheckbox {
    height: 2vh;
    width: 2vh;
}

/* Scoreboard -------------------------------------------------------------- */

.scoreboardEntry {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    outline: none;
    border: none;
}

.scoreboardNormalEntry {
    height: 50%;    
    column-gap: 28.75%;
    background-color: rgb(112, 111, 111);    
    font-size: 2vh;
}

.scoreboardMainEntry {
    height: 100%;
    column-gap: 30%;
    background-color: rgb(80, 80, 80);
    font-size: 3.25vh;
}

.innerScore {
    width: 5%;
    text-align: center;
    user-select: none;
}

.mainScore {
    display: flex;
    flex-flow: column;
    user-select: none;
}

/* Gameboard --------------------------------------------------------------- */

.gameboardPreTradesStyle {
    width: 100%;
    height: 95%;
    display: flex;
    column-gap: 0.25%;
    justify-content: center;
    flex-direction: row;
    overflow: hidden;
    font-size: 1.75vh
}

.gameboardStyle {
    width: 100%;
    height: 95%;
    display: grid;
    grid-template-rows: 32.5% 32.5% 32.5%;
    grid-template-columns: 32.5% 32.5% 32.5%;
    grid-column-gap: 1%;
    grid-row-gap: 1%;
    justify-content: center;
    overflow: hidden;
    font-size: 1.75vh;
}

.player1 {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 3;
}
.player2 {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
}
.player3 {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 2;
    grid-column-end: 3;
}
.player4 {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 3;
    grid-column-end: 4;
}

/* Table ------------------------------------------------------------------- */

.tableStyle {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
}

.dragonSelection {
    position: absolute;
    top: 0%;
    width: 15%;
    min-width: min-content;
    height: 100%;
}

.selection1 {    
    left: 34%;    
}
.selection2 {
    left: 49%;
}

.tableBox {
    border: 1px solid white;
    border-radius: max(0.30vw, 0.50vh);
    width: 100%;
    height: 90%;
}
.tableCardList {            
    display: flex;
    width: 100%;
    height: 90%;
    position: relative;
}

.dragonSelectionTableContainer {
    display: flex;
    width: 100%;
    height: 90%;
    position: relative;
}

.requestedCardTable {
    padding-left: 2%;
}

/* General Player Box -------------------------------------------------------------- */

.playerBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: max(0.30vw, 0.50vh);
    border: 1px solid white;
}

.playerInfo {
    padding-top: 1%;
    padding-right: 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 10%;
}

.playerIDSpan {
    padding-left: 2%;
    user-select: none;
}

/* In-game player box */

.inGamePlayerBox {
    height: 90%;
}

.playerCardList {
    display: flex;
    height: 70%;
    position: relative;
}


.selectionsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 20%;
}

.actionButtonsMainContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.sideActionButtonsContainer {
    display: flex;
    flex-direction: row;
}

.leftActionButtonsContainer {
    justify-content: left;    
}

.rightActionButtonsContainer {
    justify-content: right;    
}

.selectionBoxStyle {
    display: flex;
    width: 100%;
    height: min-content;
    overflow: visible;
}
.selectionButtonStyle {
    overflow: visible;
}

.phoenixSelectionContainer {
    display: flex;
    flex-direction: row;
}

.phoenixSelectMenu {
    font-size: 1.5vh;
}

/* Pre-trades Player Box --------------------------------------------------- */

.preTradePlayerBox {
    height: 45%;
}

.preTradesCol {
    display: flex;
    row-gap: 0.25%;
    flex-direction: column;
    justify-content: center;
    width: 33%;
    height: 100%
}

.preTradeCardList {
    display: flex;
    height: 40%;
    position: relative;
    user-select: none;
}

.tradingCardSlots {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40%;
}

.tradingCardSlot {
    display: flex;
    flex-direction: column;
    row-gap: 5%;
    align-items: center;
    margin-left: 3%;
    width: 100%;
    height: 100%;
}

/* Trade Phase buttons ----------------------------------------------------- */

.tradePhaseButtonContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.tradePhaseButton {
    width: 35%;
}

.inactiveButton {
    width: 35%;
    opacity: 0.5;
}

.inactiveButton:active {
    border-style: outset;
}

/* Bet messages ------------------------------------------------------------ */

.betMessage {
    user-select: none;
    height: min-content;
    color: black;
    font-weight: bold;
    padding: 0% 1%;
    border-radius: max(0.10vw, 0.30vh);
}

.tichuBetMessage {
    background-color: goldenrod;
}

.grandTichuBetMessage {
    background-color: rgb(173, 2, 2);
}

.roomContainer {
    padding-inline: 20px;
    position: relative;
    z-index: 11;
    overflow-y: auto;
    height: 280px;
}

/* Hide the scrollbar for Chrome, Safari and Opera */
.roomContainer::-webkit-scrollbar {
    display: none;
}
  
/* Hide the scrollbar for Internet Explorer, Edge and Firefox */
.roomContainer {
    -ms-overflow-style: none;  /* Internet Explorer and Edge */
    scrollbar-width: none;  /* Firefox */
}

.room {
    background-color: rgba(30, 41, 59, 0.8);
    border: 1px #b4b2b2;
    border-style: inset;
    border-radius: 12px;
    width: 71.5vw;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 10px;
}

@media screen and (min-width: 600px) {
    .room {
        width: clamp(12.9vw, 61vh, 18vw);
    }
}

.signInContainer {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 12;
}

.signInButton {
    margin: 0px;
    padding: 0.625rem 1.25rem;
    border: 0px;
    outline: 0px;
    user-select: none;
    cursor: pointer;
    background-color: var(--accent);
    color: white;
    border-radius: 0.375rem;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-duration: 100ms;
    font-family: inherit;
    font-weight: 600;
    font-size: 0.6875rem;
    letter-spacing: 0.5px;
    line-height: 1;
    text-transform: uppercase;
    min-height: 2.25rem;
    width: 100%;
    --accentLightest: hsla(227, 87%, 59%, 0.3);
    --accentLighter: hsla(227, 87%, 50%, 0.3);
    --accent: #103FEF;
    --accentDark: #0D33BF;
    --accentDarker: #0A268F;
    -webkit-font-smoothing: auto !important;
}

.createRoomContainer {
    width: 35px;
    height: 35px;
    background-color: #d3d3d3;
    border-radius: 100%;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 12;
    cursor: pointer;
}

.avatar {
    width: 32px;
    height: 32px;
    border-radius: 100%;
}

.oneUserIcon {
    width: 19px;
    height: 19px;
    min-width: 19px;
    min-height: 19px;
    padding-bottom: 5px;
    padding-right: 7px;
    margin-left: auto;
}

.twoUsersIcon {
    width: 26px;
    height: 26px;
    min-width: 26px;
    min-height: 26px;
    padding-right: 2px;
    margin-left: auto;
}

.threeUsersIcon {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    margin-left: auto;
}

.fourUsersIcon {
    width: 26px;
    height: 26px;
    min-width: 26px;
    min-height: 26px;
    padding-left: 5px;
    margin-left: auto;
}

.joinRoomButton {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    padding-top: 4px;
    padding-bottom: 6px;
    padding-inline: 10px;
    background: linear-gradient(90deg, rgba(30,133,105,1) 0%, rgb(31, 177, 18) 100%);
    border-radius: 6px;
    cursor: pointer;
}

.inactiveJoinRoomButton {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    position: relative;
    bottom: 15px;
    padding-inline: 10px;
    border-radius: 6px;
    cursor: not-allowed;
}

.leaveRoomButton {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    padding-top: 2px;
    padding-bottom: 8px;
    padding-inline: 10px;
    background: linear-gradient(90deg, rgb(197, 58, 58) 0%, rgb(238, 43, 75) 100%);
    border-radius: 6px;
    cursor: pointer;
}

.fullRoomButton {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    padding-left: 5px;
    padding-right: 15px;
    border-radius: 6px;
    cursor: not-allowed;
}

.ldsRing {
    display: inline-block;
    width: 35px;
    height: 35px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 12;
}

.ldsRing div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    margin: 8px;
    border: 8px solid #d3d3d3;
    border-radius: 50%;
    animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #d3d3d3 transparent transparent transparent;
}

.ldsRing div:nth-child(1) {
    animation-delay: -0.45s;
}

.ldsRing div:nth-child(2) {
    animation-delay: -0.3s;
}

.ldsRing div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes ldsRing {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.ldsFacebook {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 30px;
}

.ldsFacebook div {
    display: inline-block;
    position: absolute;
    bottom: 10px;
    width: 7px;
    background: #d3d3d3;
    border-radius: 12px;
    animation: ldsFacebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.ldsFacebook div:nth-child(1) {
    left: -4px;
    animation-delay: -0.24s;
}

.ldsFacebook div:nth-child(2) {
    left: 6px;
    animation-delay: -0.12s;
}

.ldsFacebook div:nth-child(3) {
    left: 16px;
    animation-delay: 0;
}

@keyframes ldsFacebook {
    0% {
        top: 8px;
        height: 32px;
    }
    50%, 100% {
        top: 16px;
        height: 16px;
    }
}