html, body, #root, .App {
  height: 100%;
}

.App-body {
  background-color: #61656f;
  background-image: url('../res/casino.webp');
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  height: 100% !important;
  width: 100%;
  color: white;
  /* overflow: hidden; */
}

.cl-userButtonPopoverActionButton__manageAccount {
  display: none;
}

.cl-userButtonPopoverFooter {
  display: none;
}